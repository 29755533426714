.map {
  height: 100%;
  display: flex;
}
.mapInfo {
  position: relative;
  z-index: 9999;
  width: 400px;
  height: 100%;
  overflow: auto;
  box-shadow: 5px 0 10px rgba(0,0,0,0.2);
}
.mapContainer {
  flex: 1;
}
.info {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 15px;
  width: 310px;
  height: 60px;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
.basicInfo {
  margin: 10px 0 10px;
  font-size: 12px;
}


// .title {
//   position: absolute;
//   z-index: 9999;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 60px;
//   color: #000;
//   background: #fff;

//   h1 {
//     margin: 0 0 15px;
//     font-size: 20px;
//     font-weight: bold;
//     font-family: 'Source Sans Pro', sans-serif;
//     letter-spacing: 1px;
//     padding: 16px 20px 0;
//     line-height: 1.4;
//     border-radius: 5px;
//   }
// }

.sidebar {
  position: absolute;
  left: 10px;
  top: 70px;
  z-index: 9999;
  width: 360px;

  .contentLayers {
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: medium;
    padding: 10px 20px 0;
    line-height: 1.4;
    // border-radius: 5px;
    // box-shadow: 0 0 10px rgba(0,0,0,0.3);
    background: #fefefe;

    h4 {
      font-size: 15px;
      font-weight: bold;
      padding: 5px 0 10px;
      margin: 0;
    }
    .layers {
      h5 {
        margin: 0;
        font-size: 14px;
      }
      p {
        margin: 0;
      }
      button {
        margin: 0 0 10px;
        padding: 0;
        border: 0;
      }
    }
    .layerItem {
      padding: 15px 20px;
      margin: 0 -20px;
      border-top: 1px solid #ccc;
    }
  }
}

.popupLayerHeader {
  margin: 1px;
  font-size: 11px;
  background: #f7f9fa;
  padding: 3px 5px;
}
.popupLayerItem,
.popupLayerItemDisabled {
  cursor: pointer;
  font-size: 11px;
  line-height: 1;
  margin: 1px;
  background: #f7f9fa;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  &:hover {
    background: darken(#f7f9fa, 5);
  }
}
.popupIcon {
  display: block;
  width: 13px;
  height: 13px;
  margin-right: 3px;
  background: url('./images/pin-question@2x.png') 0 0 no-repeat;
  background-size: 13px;
}
.popupLayerItemDisabled {
  cursor: default;
  color: #999;
  &:hover {
    background: #f7f9fa;
  }
  .popupIcon {
    background-image: url('./images/pin-no@2x.png');
  }
}
.popupLoading {
  display: block;
  width: 13px;
  height: 13px;
  margin: 7px auto;
  background: url('./images/radar.png') 0 0 no-repeat;
  background-size: 13px;
  animation-duration: 1s;
  animation-name: loopContent;
  animation-iteration-count: infinite;
}
@keyframes loopContent {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.noDataPopup {
  padding: 5px;
  font-size: 11px;
}
.popupData {
  padding: 5px;
  font-size: 12px;
}
th, 
td {
  vertical-align: top;
  padding: 3px 5px;
  background: #f7f9fa;
}

.layerLegends {
  padding-top: 5px;
  display: flex;
  text-align: left;
  > div {
    flex: 1;
  }
}
.legendItem {
  flex: 1;
  display: flex;
  align-items: center;
  margin: 5px 0 0;
}
.legendStyle {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  svg {
    width: 100%;;
    height: 100%;;
  }
}
.legendLabel {
  font-size: 11px;
  text-align: left;
}